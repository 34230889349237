// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { currency } from "Utils";
import Icon from "@mui/material/Icon";
import { useMaterialUIController, setLayout } from "context";
import bgImage from "assets/images/logo-slogan.png";
import { useNavigate } from "react-router-dom";

function Print() {
  const [controller, dispatch] = useMaterialUIController();
  const [response, setResponse] = useState([]);

  useEffect(() => {
    setLayout(dispatch, "print");

    return () => {
      setLayout(dispatch, "dashboard");
    };
  }, [dispatch]);

  const hasUpdate = useRef(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!hasUpdate.current) {
      setResponse(JSON.parse(localStorage.getItem("print") || "[]"));
      if (!localStorage.getItem("print")) {
        navigate("/conbrancas");
      } else {
        setTimeout(() => {
          window.print();
        }, 500);
        setTimeout(() => {
          navigate("/baixa");
        }, 1000);
      }
      localStorage.removeItem("print");
      hasUpdate.current = true;
    }
  }, []);
  return (
    <MDBox
      pb={3}
      style={{
        backgroundColor: "#fff",
      }}
    >
      <MDBox mb={3}>
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#000",
            marginBottom: "1rem",
          }}
        >
          <img src={bgImage} alt="logo" style={{ width: "200px" }} />
        </MDBox>
        <h4 style={{ textAlign: "center" }}>
          <strong>Relatório de Baixa</strong>
        </h4>
        <MDBox px={2}>
          {response?.map((item, index) => (
            <MDBox
              style={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                margin: "1rem",
              }}
              key={item.id}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexWrap: "wrap",
                  width: "100%",
                  padding: "1rem 1rem 0 1rem",
                }}
              >
                <MDBox>
                  <strong>Cliente: </strong>
                  {item.client_id}
                </MDBox>
                <MDBox>
                  <strong>Ordem de pagamento: </strong>
                  {item.order_id}
                </MDBox>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexWrap: "wrap",
                  width: "100%",
                  padding: "1rem 1rem 0 1rem",
                }}
              >
                <MDBox>
                  <strong>Total: </strong>
                  {currency(item.payment_amount)}
                </MDBox>
                <MDBox>
                  <strong>Data: </strong>
                  {moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                </MDBox>
              </div>
              <div
                style={{
                  padding: "1rem",
                }}
              >
                <strong>Parcelas: </strong> <br />
                {item.installments.map((installment) => (
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      flexWrap: "wrap",
                      alignItems: "center",
                      width: "100%",
                      padding: "1rem 1rem 1rem 1rem",
                    }}
                    key={installment.id}
                  >
                    <Icon>arrow_right_alt</Icon>
                    <MDBox>
                      <strong>parcela: </strong>
                      {installment.installment_number}
                    </MDBox>
                    <MDBox>
                      <strong>Loja: </strong>
                      {installment.store_id}
                    </MDBox>
                    <MDBox>
                      <strong>Contrato: </strong>
                      {installment.contract_id}
                    </MDBox>
                    <MDBox>
                      <strong>Valor: </strong>
                      {currency(installment.total)}
                    </MDBox>
                  </div>
                ))}
              </div>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Print;
