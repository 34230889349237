// @mui material components
import Card from "@mui/material/Card";
import LoadIcon from "assets/images/loadicon.svg";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { useRef, useState } from "react";
import api from "services/api";
import { logout } from "services/auth";
import { Alert } from "@mui/material";
import moment from "moment";
import { currency } from "Utils";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";

const cnabeBaseUrl =
  process.env.REACT_APP_CNAB_BASEURL || "https://cnab-feirao.s1solucoes.com.br";

function Baixa() {
  const [load, setLoad] = useState(false);

  const [error, setError] = useState("");
  const [response, setResponse] = useState([]);

  function sendReturn(returPayload) {
    setLoad(true);
    api
      .post("/api/admin/payment/confirm/billet", returPayload)
      .then((response) => {
        setFile(null);
        fileInput.current.value = null;
        setResponse(response.data.billetsPaid);
        if (response.data.billetsPaid.length === 0) {
          setError(
            "Nenhuma ordem de pagamento foi encontrada para este arquivo."
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          logout();
        }
        setError(err?.response?.data?.message);
      })
      .finally(() => setLoad(false));
  }

  function sendFile(e) {
    //  preventDefault
    e.preventDefault();
    setLoad(true);
    const formData = new FormData();
    formData.append("payload", file);
    setError("");
    api
      .post(`${cnabeBaseUrl}/cobranca/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        sendReturn(response.data);
      })
      .catch((err) => {
        setError("Error ao processar arquivo.");
        if (err.response.status === 401) {
          logout();
        }
        setLoad(false);
      });
  }

  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox py={3} px={2}>
              <MDTypography>
                Confirmar pagamento de boleto <br />
              </MDTypography>
              <MDTypography variant="caption">
                Envie o aquivo de retorno do banco para confirmar o pagamento
              </MDTypography>{" "}
              <br />
              <form onSubmit={sendFile}>
                <input
                  type="file"
                  onChange={(event) => {
                    setFile(event.target.files[0]);
                  }}
                  ref={fileInput}
                  disabled={load || response.length > 0}
                />
                {error && (
                  <Alert
                    style={{
                      marginTop: "16px",
                    }}
                    severity="error"
                  >
                    {error}
                  </Alert>
                )}
                <MDBox mt={3}>
                  <MDButton
                    variant="contained"
                    color="success"
                    fw
                    disabled={load || !file || response.length > 0}
                    type="submit"
                  >
                    Enviar{" "}
                    {load && <img src={LoadIcon} alt="load" width="20" />}
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
            {response.length > 0 && (
              <Alert severity="success">
                Pagamento confirmado com sucesso!
              </Alert>
            )}
            <MDBox py={3} px={2}>
              {response?.map((item, index) => (
                <Card
                  style={{
                    border: "1px solid #ccc",
                    margin: "1rem",
                  }}
                  key={item.id}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      flexWrap: "wrap",
                      width: "100%",
                      padding: "1rem 1rem 0 1rem",
                    }}
                  >
                    <MDBox>
                      <strong>Cliente: </strong>
                      {item.client_id}
                    </MDBox>
                    <MDBox>
                      <strong>Data: </strong>
                      {moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </MDBox>
                    <MDBox>
                      <strong>Ordem de pagamento: </strong>
                      {item.order_id}
                    </MDBox>
                    <MDBox>
                      <strong>Total: </strong>
                      {currency(item.payment_amount)}
                    </MDBox>
                  </div>
                  <div
                    style={{
                      padding: "1rem",
                    }}
                  >
                    <strong>Parcelas: </strong> <br />
                    {item.installments.map((installment) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            flexWrap: "wrap",
                            width: "100%",
                            padding: "1rem 1rem 0 1rem",
                          }}
                        >
                          <MDBox>
                            <strong>Contrato: </strong>
                            {installment.contract_id}
                          </MDBox>
                          <MDBox>
                            <strong>Loja: </strong>
                            {installment.store_id}
                          </MDBox>
                          <MDBox>
                            <strong>parcela: </strong>
                            {installment.installment_number}
                          </MDBox>
                          <MDBox>
                            <strong>Valor: </strong>
                            {currency(installment.total)}
                          </MDBox>
                        </div>
                        <hr />
                      </>
                    ))}
                  </div>
                </Card>
              ))}
            </MDBox>
            {response.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                  gap: "1rem",
                }}
              >
                <MDButton
                  variant="outlined"
                  color="success"
                  onClick={() => setResponse([])}
                >
                  Enviar outro arquivo
                </MDButton>
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("print", JSON.stringify(response));
                    navigate("/print");
                  }}
                >
                  <Icon
                    fontSize="small"
                    style={{
                      marginRight: "0.5rem",
                    }}
                  >
                    printer
                  </Icon>
                  Imprimir
                </MDButton>
              </div>
            )}
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Baixa;
