/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import LoadIcon from "assets/images/loadicon.svg";
// Images
import bgImage from "assets/images/logo-slogan.png";
import bgImage2 from "assets/images/maxresdefault.jpg";
import api from "services/api";
import { setLogin } from "services/auth";
import MDAlert from "components/MDAlert";
import { Alert } from "@mui/material";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [load, setLoad] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  function sendLongin(e) {
    e.preventDefault();
    setLoad(true);
    setError("");
    api
      .post("/api/admin/login", { userName, password })
      .then((response) => {
        setLogin(response.data.acessToken, response.data.admin);
      })
      .catch((error) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setLoad(false);
      });
  }
  return (
    <BasicLayout image={bgImage2}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Entrar
          </MDTypography>
          <img src={bgImage} width="250px" alt="" />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }} color="error" dismissible>
              {error}
            </Alert>
          )}
          <form onSubmit={sendLongin}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                required
                label="Usuário"
                fullWidth
                value={userName}
                onChange={(e) => setUsername(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                required
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Permanecer logado
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={load}
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
              >
                {load ? <img width="25px" src={LoadIcon} alt="" /> : "entrar"}
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
