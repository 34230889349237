// Material Dashboard 2 React layouts

// @mui icons
import Icon from "@mui/material/Icon";
import Clients from "layouts/clients";
import { isAuthenticated } from "services/auth";
import { Navigate } from "react-router-dom";
import Orders from "layouts/orders";
import Baixa from "layouts/Baixa";

function PrivateRoute({children}) {
  const auth = isAuthenticated();
  return auth ? <>{children}</> : <Navigate to="/authentication/sign-in" />;
}

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: (
  //     <PrivateRoute>
  //       <Dashboard />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  {
    type: "collapse",
    name: "Cobranças",
    key: "Cobranças",
    icon: <Icon fontSize="small">request_quote</Icon>,
    route: "/cobrancas",
    component: (
      <PrivateRoute>
        <Clients />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Ordens de pagamento",
    key: "ordens",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/ordens-de-pagamento",
    component: (
      <PrivateRoute>
        <Orders />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Confirmar pagamaentos",
    key: "ordens",
    icon: <Icon fontSize="small">upload_file</Icon>,
    route: "/baixa",
    component: (
      <PrivateRoute>
        <Baixa />
      </PrivateRoute>
    ),
  },
  // {
  //   type: "collapse",
  //   name: "Ordens de pagamento",
  //   key: "ordens",
  //   icon: <Icon fontSize="small">request_quote</Icon>,
  //   route: "/ordens-de-pagamento",
  //   component: (
  //     <PrivateRoute>
  //       <Clients />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
