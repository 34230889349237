export function currency(number) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(number);
}

export function dateFormat(date) {
  return new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
    new Date(date)
  );
}

export function cpfMask(value) {
  let onlyNumbers = value.replace(/\D/g, "");
  onlyNumbers = onlyNumbers.substring(0, 11);
  onlyNumbers = onlyNumbers.replace(/(\d{3})(\d)/, "$1.$2");
  onlyNumbers = onlyNumbers.replace(/(\d{3})(\d)/, "$1.$2");
  onlyNumbers = onlyNumbers.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return onlyNumbers;
}
export function cnpjMask(value) {
  let onlyNumbers = value.replace(/\D/g, "");
  onlyNumbers = onlyNumbers.substring(0, 14);
  onlyNumbers = onlyNumbers.replace(/(\d{2})(\d)/, "$1.$2");
  onlyNumbers = onlyNumbers.replace(/(\d{3})(\d)/, "$1.$2");
  onlyNumbers = onlyNumbers.replace(/(\d{3})(\d)/, "$1/$2");
  // onlyNumbers = onlyNumbers.replace(/(\d{4})(\d)/, "$1-$2");
  onlyNumbers = onlyNumbers.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  return onlyNumbers;
}

export const dateMask = (v) => {
  let onlyNumbers = v.replace(/\D/g, "");
  onlyNumbers = onlyNumbers.substring(0, 8);
  onlyNumbers = onlyNumbers.replace(/(\d{2})(\d)/, "$1/$2");
  onlyNumbers = onlyNumbers.replace(/(\d{2})(\d)/, "$1/$2");

  return onlyNumbers;
};
export const dateCardMask = (v) => {
  let onlyNumbers = v.replace(/\D/g, "");
  onlyNumbers = onlyNumbers.substring(0, 4);
  onlyNumbers = onlyNumbers.replace(/(\d{2})(\d)/, "$1/$2");

  return onlyNumbers;
};
export const dateCardNumberMask = (v) => {
  let onlyNumbers = v.replace(/\D/g, "");
  onlyNumbers = onlyNumbers.substring(0, 16);
  onlyNumbers = onlyNumbers.replace(/(\d{4})(\d)/, "$1 $2");
  onlyNumbers = onlyNumbers.replace(/(\d{4})(\d)/, "$1 $2");
  onlyNumbers = onlyNumbers.replace(/(\d{4})(\d)/, "$1 $2");

  return onlyNumbers;
};

export function hiddenCpf(cpf) {
  let cpfhidden = cpf.split(".")[0] + ".***.***-" + cpf.split("-")[1];
  return cpfhidden;
}
