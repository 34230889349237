export const TOKEN_KEY = "@feirao-Token-adm";
export const USER_KEY = "@feirao-USER-adm";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser = () =>
  JSON.parse(localStorage.getItem("@feirao-admin-data"));
export const setLogin = (token, admin) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem("@feirao-admin-data", JSON.stringify(admin));
  window.location.href = "/cobrancas";
};

export const setUserStorage = (user) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("@feirao-admin-data");
  window.location.href = "/authentication/sign-in";
};
