// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LoadIcon from "assets/images/loadicon.svg";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useState } from "react";
import { cpfMask } from "Utils";
import api from "services/api";
import { logout } from "services/auth";
import MDAvatar from "components/MDAvatar";
import {
  Alert,
  Fade,
  FormControl,
  FormControlLabel,
  Icon,
  Modal,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { currency } from "Utils";
import { dateFormat } from "Utils";
import { getUser } from "services/auth";
import { cnpjMask } from "Utils";
import { useNavigate } from "react-router-dom";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name[0]}${name.split(" ")[1][0] ? name.split(" ")[1][0] : ""}`,
  };
}
const Author = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar
      variant="gradient"
      color="info"
      {...stringAvatar(name)}
      name={name}
      size="sm"
    />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

var columns = [
  { Header: "Contrato - parcela", accessor: "author", align: "left" },
  { Header: "Valor", accessor: "valor", align: "left" },
  { Header: "Vencimento", accessor: "vencimento", align: "center" },
  { Header: "juros", accessor: "juros", align: "center" },
  { Header: "desconto", accessor: "desconto", align: "center" },
  { Header: "valor pago", accessor: "valorpago", align: "center" },
  { Header: "Total", accessor: "total", align: "center" },
  { Header: "lançar desconto", accessor: "input", align: "center" },
  { Header: "Ação", accessor: "action", align: "center" },
];
var columnsI = [
  { Header: "Contrato - parcela", accessor: "author", align: "left" },
  { Header: "Valor", accessor: "valor", align: "left" },
  { Header: "Vencimento", accessor: "vencimento", align: "center" },
  { Header: "Juros", accessor: "juros", align: "center" },
  { Header: "Desconto", accessor: "desconto", align: "center" },
  { Header: "Valor pago", accessor: "valorpago", align: "center" },
  { Header: "Desconto no juros", accessor: "total", align: "center" },
  { Header: "Valor final", accessor: "action", align: "center" },
];

function findDiscount(store) {
  let st = getUser().discounts.find((x) => x.store_id === store);
  if (st) {
    return st;
  }
  return {
    capital_discount: null,
    fee_discount: null,
  };
}

columns = [
  { Header: "Contrato - parcela", accessor: "author", align: "left" },
  { Header: "Valor", accessor: "valor", align: "left" },
  { Header: "Vencimento", accessor: "vencimento", align: "center" },
  { Header: "juros", accessor: "juros", align: "center" },
  { Header: "desconto", accessor: "desconto", align: "center" },
  { Header: "valor pago", accessor: "valorpago", align: "center" },
  { Header: "Total", accessor: "total", align: "center" },
  { Header: "lançar desconto", accessor: "input", align: "center" },
  { Header: "Desconto no capital", accessor: "inputCapital", align: "center" },
  { Header: "Ação", accessor: "action", align: "center" },
];
columnsI = [
  { Header: "Contrato - parcela", accessor: "author", align: "left" },
  { Header: "Valor", accessor: "valor", align: "left" },
  { Header: "Vencimento", accessor: "vencimento", align: "center" },
  { Header: "Juros", accessor: "juros", align: "center" },
  { Header: "Desconto", accessor: "desconto", align: "center" },
  { Header: "Valor pago", accessor: "valorpago", align: "center" },
  { Header: "Desconto no juros", accessor: "total", align: "center" },
  { Header: "Desconto no capital", accessor: "inputCapital", align: "center" },
  { Header: "Valor final", accessor: "action", align: "center" },
];

function isValidCPF(cpf) {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  let cpfArr = cpf.split("");
  const validator = cpfArr
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map((el) => +el);
  const toValidate = (pop) =>
    cpfArr
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
}

function Clients() {
  const [cpfInput, setCpfInput] = useState("");
  const [load, setLoad] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [client, setClient] = useState();
  const [error, setError] = useState("");
  const [openI, setOpenI] = useState([]);
  const [openIToshow, setOpenIToshow] = useState([]);
  const [filterContract, setFilterContract] = useState("");
  const [selectedI, setSelectedI] = useState([]);
  const [loginType, setLoginType] = useState("CPF");
  const navigate = useNavigate();
  const handleRadioChange = (event) => {
    setLoginType(event.target.value);
    setCpfInput("");
    setError("");
    setSelectedI([]);
    setClient(undefined);
  };

  function changediscount(value, contract, number) {
    let newI = openI;
    let index = newI.findIndex(
      (x) => x.contract_id === contract && x.installment_number === number
    );
    newI[index].fee_discount = Number(value);
    if (!value || Number(value) < 0) {
      newI[index].fee_discount = undefined;
    }
    setOpenI([...newI]);
  }
  function changediscountCapital(value, contract, number) {
    let newI = openI;
    let index = newI.findIndex(
      (x) => x.contract_id === contract && x.installment_number === number
    );
    newI[index].capital_discount = Number(value);
    if (!value || Number(value) < 0) {
      newI[index].capital_discount = undefined;
    }
    setOpenI([...newI]);
  }

  function sendGetInstallments(e) {
    e.preventDefault();
    setLoad(true);
    setFilterContract("");
    if (loginType === "CPF" && !isValidCPF(cpfInput)) {
      setError("CPF Inválido");
      setLoad(false);
      setSelectedI([]);
      setOpenIToshow([]);
      setClient(undefined);
      setOpenI([]);
      return;
    }
    api
      .get(`/api/admin/installments?cpf=${cpfInput}`)
      .then((response) => {
        setClient(response.data);
        setError("");
        setSelectedI([]);
        setOpenIToshow(response.data.open_installments);
        setOpenI(response.data.open_installments);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        }
        setError(err.response.data.message);
        setSelectedI([]);
        setOpenIToshow([]);
        setOpenI([]);
        setClient(undefined);
      })
      .finally(() => setLoad(false));
  }
  function filter(e) {
    e.preventDefault();
    setOpenIToshow(
      openI.filter((x) => String(x.contract_id).includes(filterContract))
    );
  }
  function addI(contract, number) {
    let newI = openI;
    let index = newI.findIndex(
      (x) => x.contract_id === contract && x.installment_number === number
    );
    if (
      selectedI.findIndex(
        (x) => x.contract_id === contract && x.installment_number === number
      ) === -1
    ) {
      setSelectedI([...selectedI, newI[index]]);
    }
  }
  function removeI(contract, number) {
    let newIns = selectedI;
    let index = newIns.findIndex(
      (x) => x.contract_id === contract && x.installment_number === number
    );
    newIns.splice(index, 1);
    setSelectedI([...newIns]);
    console.log(openIToshow);
  }

  function sendPayment(method) {
    setLoad(true);
    api
      .post(`/api/admin/payment/generate`, {
        clientId: client.id,
        paymentMethod: method,
        paymentAmount: selectedI.reduce(
          (x, y) => x + y.total - (y.fee_discount || 0),
          0
        ),
        installments: selectedI.map((x) => ({
          contract_id: x.contract_id,
          installmentNumber: x.installment_number,
          store_id: x.store_id,
          capital_discount: x.capital_discount,
          fee_discount: x.fee_discount,
        })),
      })
      .then((response) => {
        setCartOpen(false);
        setSelectedI([]);
        console.log(response.data);
        navigate(`/ordens-de-pagamento/${response.data._id}`);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        }
        setError(err.response.data.message);
      })
      .finally(() => setLoad(false));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox py={3} px={2}>
              <form onSubmit={sendGetInstallments}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleRadioChange}
                    value={loginType}
                  >
                    <FormControlLabel
                      value="CPF"
                      control={<Radio />}
                      label="CPF"
                      labelPlacement="right"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="CNPJ"
                      value="CNPJ"
                      labelPlacement="right"
                    />
                  </RadioGroup>
                </FormControl>
                <MDBox style={{ display: "flex", gap: "1rem" }}>
                  <MDInput
                    type="text"
                    label={loginType}
                    required
                    fullWidth
                    value={cpfInput}
                    onChange={(e) =>
                      setCpfInput(
                        loginType === "CPF"
                          ? cpfMask(e.target.value)
                          : cnpjMask(e.target.value)
                      )
                    }
                  />
                  <MDButton
                    disabled={load}
                    type="submit"
                    variant="gradient"
                    color="success"
                    style={{ minWidth: "150px" }}
                  >
                    {load ? (
                      <img width="25px" src={LoadIcon} alt="" />
                    ) : (
                      "Buscar"
                    )}
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox mb={6}>
          {client && (
            <>
              <Card>
                <MDBox py={3} px={2}>
                  <Author name={client.name + " "} email={client.cpf} />
                </MDBox>
              </Card>
            </>
          )}
          {error && (
            <Card>
              <MDBox py={3} px={2}>
                <Alert severity="error">{error}</Alert>
              </MDBox>
            </Card>
          )}
        </MDBox>
        {client && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Parcelas em aberto
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDBox py={3} px={2}>
                    <form onSubmit={filter}>
                      <MDBox style={{ display: "flex", gap: "1rem" }}>
                        <MDInput
                          type="text"
                          label="filtrar contrato"
                          required
                          fullWidth
                          value={filterContract}
                          onChange={(e) => {
                            if (e.target.value.length === 0) {
                              setOpenIToshow(openI);
                            }
                            setFilterContract(e.target.value);
                          }}
                        />
                        <MDButton
                          disabled={load}
                          type="submit"
                          variant="gradient"
                          color="success"
                          style={{ minWidth: "150px" }}
                        >
                          {load ? (
                            <img width="25px" src={LoadIcon} alt="" />
                          ) : (
                            "Buscar"
                          )}
                        </MDButton>
                      </MDBox>
                    </form>
                  </MDBox>
                  <DataTable
                    table={{
                      columns,
                      rows: openIToshow.map((x) => ({
                        author: x.contract_id + " - " + x.installment_number,
                        valor: currency(x.installment_value),
                        vencimento: dateFormat(x.due_date),
                        juros: currency(x.fees),
                        desconto: currency(x.discount * -1),
                        valorpago: currency(x.amortized_value),
                        total: currency(x.total),
                        input: (
                          <MDBox style={{ minWidth: "220px" }}>
                            <MDInput
                              type="number"
                              label="Valor a ser descontado"
                              required
                              disabled={
                                selectedI.findIndex(
                                  (y) =>
                                    y.contract_id === x.contract_id &&
                                    y.installment_number ===
                                      x.installment_number
                                ) !== -1
                              }
                              size="sm"
                              fullWidth
                              value={x.fee_discount}
                              error={
                                (Number(x.fee_discount) / x.fees) * 100 >
                                  Number(
                                    findDiscount(
                                      x.store_id
                                    )?.fee_discount.split("%")[0]
                                  ) && Number(x.fee_discount) > 0
                              }
                              helperText={
                                (Number(x.fee_discount) / x.fees) * 100 <
                                  Number(
                                    findDiscount(
                                      x.store_id
                                    )?.fee_discount.split("%")[0]
                                  ) || !x.fee_discount
                                  ? !x.fee_discount
                                    ? ""
                                    : `Valor em %: ${(
                                        (x.fee_discount / x.fees) *
                                        100
                                      ).toFixed(2)}%`
                                  : `Valor de desconto excedido`
                              }
                              onChange={(e) =>
                                changediscount(
                                  e.target.value,
                                  x.contract_id,
                                  x.installment_number
                                )
                              }
                            />
                          </MDBox>
                        ),
                        inputCapital: findDiscount(x.store_id)
                          ?.capital_discount ? (
                          <MDBox style={{ minWidth: "220px" }}>
                            <MDInput
                              type="number"
                              label="Valor a ser descontado"
                              required
                              disabled={
                                selectedI.findIndex(
                                  (y) =>
                                    y.contract_id === x.contract_id &&
                                    y.installment_number ===
                                      x.installment_number
                                ) !== -1
                              }
                              size="sm"
                              fullWidth
                              value={x.capital_discount}
                              error={
                                (Number(x.capital_discount) /
                                  x.installment_value) *
                                  100 >
                                  Number(
                                    findDiscount(
                                      x.store_id
                                    )?.capital_discount.split("%")[0]
                                  ) && Number(x.capital_discount) > 0
                              }
                              helperText={
                                (Number(x.capital_discount) /
                                  x.installment_value) *
                                  100 <
                                  Number(
                                    findDiscount(
                                      x.store_id
                                    )?.capital_discount.split("%")[0]
                                  ) || !x.capital_discount
                                  ? !x.capital_discount
                                    ? ""
                                    : `Valor em %: ${(
                                        (x.capital_discount /
                                          x.installment_value) *
                                        100
                                      ).toFixed(2)}%`
                                  : `Valor de desconto excedido`
                              }
                              onChange={(e) =>
                                changediscountCapital(
                                  e.target.value,
                                  x.contract_id,
                                  x.installment_number
                                )
                              }
                            />
                          </MDBox>
                        ) : (
                          <></>
                        ),
                        action:
                          selectedI.findIndex(
                            (y) =>
                              y.contract_id === x.contract_id &&
                              y.installment_number === x.installment_number
                          ) === -1 ? (
                            <MDButton
                              component="button"
                              variant="caption"
                              color="text"
                              disabled={
                                ((Number(x.fee_discount) / x.fees) * 100 >
                                  Number(
                                    findDiscount(
                                      x.store_id
                                    )?.fee_discount.split("%")[0]
                                  ) &&
                                  Number(x.fee_discount) > 0) ||
                                (getUser.capital_discount &&
                                  !(
                                    (Number(x.capital_discount) /
                                      x.installment_value) *
                                      100 <
                                      Number(
                                        findDiscount(
                                          x.store_id
                                        )?.capital_discount.split("%")[0]
                                      ) || !x.capital_discount
                                  ))
                              }
                              onClick={() =>
                                addI(x.contract_id, x.installment_number)
                              }
                              fontWeight="medium"
                            >
                              adicionar
                            </MDButton>
                          ) : (
                            <MDButton
                              component="button"
                              variant="contained"
                              color="success"
                              type="button"
                              onClick={() =>
                                removeI(x.contract_id, x.installment_number)
                              }
                              fontWeight="medium"
                            >
                              adicionado
                            </MDButton>
                          ),
                      })),
                    }}
                    // isSorted={true}
                    entriesPerPage={true}
                    // showTotalEntries={true}
                    // entriesPerPageOptions={[10, 20, 30]}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {/* <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card> */}
            </Grid>
          </Grid>
        )}
      </MDBox>
      {selectedI.length > 0 && (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="3.25rem"
          height="3.25rem"
          bgColor="yellow"
          shadow="sm"
          borderRadius="50%"
          position="fixed"
          right="2rem"
          bottom="7rem"
          zIndex={99}
          color="dark"
          sx={{ cursor: "pointer" }}
          onClick={() => setCartOpen(true)}
        >
          <Icon fontSize="small" color="inherit">
            shopping_basket
          </Icon>
        </MDBox>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={cartOpen}
        onClose={() => setCartOpen(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={cartOpen}>
          <Card
            sx={{
              width: "100%",
              maxWidth: "1300px",
              mx: "auto",
              padding: "1rem",
            }}
          >
            <h4 id="transition-modal-title">Parcelas selecionadas</h4>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    {columnsI.map((x) => (
                      <TableCell>
                        <strong>{x.Header}</strong>
                      </TableCell>
                    ))}
                  </TableRow>
                  {selectedI.map((x) => (
                    <TableRow key={x.contract_id + "_" + x.installment_number}>
                      <TableCell>
                        {x.contract_id} - {x.installment_number}
                      </TableCell>
                      <TableCell>{currency(x.installment_value)}</TableCell>
                      <TableCell>{dateFormat(x.due_date)}</TableCell>
                      <TableCell>{currency(x.fees)}</TableCell>
                      <TableCell>{currency(x.discount)}</TableCell>
                      <TableCell>{currency(x.amortized_value)}</TableCell>
                      <TableCell>{currency(x.fee_discount || 0)}</TableCell>
                      <TableCell>{currency(x.capital_discount || 0)}</TableCell>
                      <TableCell>
                        {currency(
                          x.total -
                            (x.fee_discount || 0) -
                            (x.capital_discount || 0)
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <MDBox
              sx={{
                display: "flex",
                mt: "1rem",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <span>
                Valor total:{" "}
                {currency(
                  selectedI.reduce(
                    (x, y) =>
                      x +
                      y.total -
                      (y.fee_discount || 0) -
                      (y.capital_discount || 0),
                    0
                  )
                )}
              </span>
              <MDButton
                disabled={load}
                onClick={() => sendPayment("PIX")}
                variant="contained"
                color="success"
              >
                {load ? (
                  <img width="25px" src={LoadIcon} alt="" />
                ) : (
                  "Criar ordem de pagamento PIX"
                )}
              </MDButton>
              <MDButton
                disabled={load}
                onClick={() => sendPayment("BILLET")}
                variant="contained"
                color="success"
              >
                {load ? (
                  <img width="25px" src={LoadIcon} alt="" />
                ) : (
                  "Criar ordem de pagamento BOLETO"
                )}
              </MDButton>
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    </DashboardLayout>
  );
}

export default Clients;
