// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LoadIcon from "assets/images/loadicon.svg";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
// Data
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import api from "services/api";
import { logout } from "services/auth";
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { currency } from "Utils";
import { dateFormat } from "Utils";
import { Link, useParams, useRoutes } from "react-router-dom";
const redirect_base = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const columnsI = [
  { Header: "Contrato - parcela", accessor: "author", align: "left" },
  { Header: "Valor", accessor: "valor", align: "left" },
  { Header: "Vencimento", accessor: "vencimento", align: "center" },
  { Header: "Juros", accessor: "juros", align: "center" },
  { Header: "Desconto", accessor: "desconto", align: "center" },
  { Header: "Valor pago", accessor: "valorpago", align: "center" },
  { Header: "Desconto no juros", accessor: "total", align: "center" },
  { Header: "Desconto no capital", accessor: "input", align: "center" },
  { Header: "Valor final", accessor: "action", align: "center" },
];

function Orders() {
  const { id } = useParams();
  const [load, setLoad] = useState(false);

  const [alertCopy, setAlertCopy] = useState("");
  const [orders, setOrders] = useState();
  const [error, setError] = useState("");

  function sendGetInstallments() {
    setLoad(true);
    api
      .get(`/api/admin/payment/orders`)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        }
        setError(err.response.data.message);
      })
      .finally(() => setLoad(false));
  }

  useEffect(() => {
    sendGetInstallments();
  }, []);

  function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  }
  async function copyText(t) {
    if (window.isSecureContext && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(t);
        setAlertCopy("Link copiado!");
        setTimeout(() => {
          setAlertCopy("");
        }, 4000);
      } catch (e) {
        console.log(e);
      }
    } else {
      unsecuredCopyToClipboard(t);
      setAlertCopy("Link copiado!");
      setTimeout(() => {
        setAlertCopy("");
      }, 4000);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <MDBox mb={3}>
          <>
            {load ? (
              <img src={LoadIcon} alt="" />
            ) : (
              <>
                {orders?.map((x) => (
                  <Card sx={{ mb: "1rem" }}>
                    <MDBox py={3} px={2} sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                      <MDBox>
                        <MDTypography>
                          Ordem de pagamento N {x?.order_id}
                        </MDTypography>
                        <MDTypography sx={{ mt: "0.5rem" }}>
                          Valor total: {currency(x?.payment_amount)}
                        </MDTypography>
                        <MDTypography sx={{ mt: "0.5rem" }}>
                          Criada em:{" "}
                          {moment(x?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                        </MDTypography>
                        <MDTypography sx={{ mt: "0.5rem" }}>
                          Status: {x?.status}
                        </MDTypography>
                      </MDBox>
                      <MDBox sx={{ mt: "1rem", display: "flex", gap: "1rem" }}>
                        <Link to={x._id}>
                        <MDButton
                          variant="contained"
                          color="info"
                        >
                          Ver ordem
                          <Icon
                            sx={{ ml: "0.5rem" }}
                            fontSize="small"
                            color="inherit"
                          >
                            launch
                          </Icon>
                        </MDButton></Link>
                      </MDBox>
                    </MDBox>
                  </Card>
                ))}
              </>
            )}
          </>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Orders;
